<template>
  <main class="OrdersExport">
    <TheSubHeader
      icon="export-alt"
      title="Exportar Pedidos"
      page-title-tag="Orders Export"
      type="filter"
      :actions="['import', 'see', 'addnew']"
      organizer-gap="1rem"
      organizer-columns="240px repeat(5, 1fr) auto"
      align-items="center"
    >
      <template #beforeButtons>
        <BaseButton
          v-if="permissions && permissions.orders && permissions.orders.incomingOrderButton"
          icon="package-request"
          color="dark"
          label="Recebimento de físico"
          :track="trackSubheaderButtonString('Orders Export', 'Orders Incoming')"
          @click="goToIncomingOrders"
        />
        <BaseButton
          v-if="permissions && permissions.orders && permissions.orders.changeOrderStatusButton"
          icon="export-alt"
          color="dark"
          label="Mudar Status"
          class="TheSubHeader__button"
          :track="trackSubheaderButtonString('Orders Export', 'Orders Change Status')"
          @click="goToChangeStatus"
        />
      </template>
      <template #filter>
        <!-- Period -->
        <BaseDatePicker
          v-model="formattedDateRange"
          label="Intervalo entre datas (max. 15 dias)"
          :max-range="15"
          @input="resetSearch()"
        >
          <template #footer>
            <BaseRadioGroup
              v-model="filters.lastStatus"
              label="Intervalo da Data:"
              name="intervalDateSelected"
              :flex="true"
              :list="intervalsDate"
            />
          </template>
        </BaseDatePicker>
        <!-- Lojista -->
        <BaseTreeSelect
          v-model="filters.storeLabel"
          name="Lojista"
          label="Lojista"
          placeholder="Selecione"
          multiple
          :select-options="null"
          :fetch-options="'stores/fetchStoresAsync'"
          @input="resetSearch()"
        />
        <!-- Transporters -->
        <BaseTreeSelect
          v-model="filters.storeTransporters"
          name="Transportadora"
          label="Transportadora"
          placeholder="Selecione"
          multiple
          :select-options="transporters"
          @input="resetSearch()"
        />
        <!-- Driver -->
        <BaseTreeSelect
          v-model="filters.storeDrivers"
          name="Motorista"
          label="Motorista"
          placeholder="Selecione"
          multiple
          suggestions="searchDrivers"
          @input="resetSearch()"
        />
        <!-- Type Search Column -->
        <BaseTreeSelect
          v-model="filters.typeSearchColumn"
          name="Pesquisar pedidos por"
          label="Pesquisar pedidos por"
          placeholder="Selecione"
          :clearable="false"
          :select-options="optionsFields.typeSearchColumn"
          @input="resetSearch()"
        />
        <!-- Orders -->
        <BaseTreeSelect
          ref="query"
          v-model="filters.filterOrders"
          :name="filters.typeSearchColumn.name"
          :label="filters.typeSearchColumn.name"
          placeholder="Pesquisar"
          multiple
          :select-all="false"
          :enable-paste="true"
          :allow-create="true"
          :disable-branch-nodes="true"
          :max-items="5000"
          @input="resetSearch()"
        />
        <!-- Export -->
        <BaseButton
          :disabled="disabledFields.orderExport"
          label="Exportar"
          theme="simple"
          filled
          color="primary-base"
          text-color="white"
          @click="exportReport"
        />
      </template>
      <template v-if="allTags.length" #tag>
        <FiltersTag :tags="allTags" @removeTag="removeTag" @clear="clearAllTags" />
      </template>
    </TheSubHeader>
    <div class="OrdersExport__content">
      <h1 class="OrdersExport__Title">
        HISTÓRICO DE EXPORTAÇÃO
      </h1>
      <div class="OrdersExport__table">
        <DataTable
          is-sticky
          :header="reportsRequestedWithLoading.data.headers"
          :table="reportsRequestedWithLoading.data.content"
          @download="download"
          @seeErrors="showDefaultErrorMessage"
        />
        <Pagination @page="fetchReports()" @paginate="fetchReports()" />
      </div>
    </div>
  </main>
</template>

<script>
import { TheSubHeader } from '@/components/organisms'
import { BaseTreeSelect, BaseButton, BaseDatePicker, BaseRadioGroup } from '@/components/atoms'
import { Pagination, DataTable, FiltersTag } from '@/components/molecules'
import { orders } from '@/api'
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  name: 'OrdersExport',
  components: {
    TheSubHeader,
    BaseButton,
    BaseDatePicker,
    BaseTreeSelect,
    DataTable,
    FiltersTag,
    Pagination,
    BaseRadioGroup
  },
  data(){
    return {
      //Filters
      filters: {
        lastStatus: 0,
        dateRange: {
          init: null,
          end: null,
        },
        storeLabel: [],
        storeTransporters: [],
        typeSearchColumn: {id: 'order_number', name: 'Nº do pedido'},
        storeDrivers: [],
        filterOrders: []
      },

      //Filters Radio
      intervalsDate: [
        {id: 0 , name: 'Pedido', value: 0},
        {id: 1, name: 'Último Status', value: 1 },
      ],

      //Tags
      allTags: [],

      //Helpers
      updating: false,
      time: null,
      timeOutUpdate: 10 * 1000,

    }
  },
  computed:{
    ...mapState({
      //Filters
      transporters: state => state.transporters.transporters,
      //Table
      reportsRequested: state => state.orders.reportsRequested,
      page: state => state.pagination.page,
      paginate: state => state.pagination.paginate,
      //Helpers
      permissions: state => state.auth.permissions,
    }),

    ...mapGetters({
      reportsRequestedWithLoading: 'orders/reportsRequestedWithLoading'
    }),

    //Disabled Fields
    disabledFields() {
      return {
        //Filters
        orderExport:
          ((!this.filters.dateRange.init && !this.filters.dateRange.end) ||
            this.filters.filterOrders.length === 0) &&
          ((!this.filters.dateRange.init && !this.filters.dateRange.end) ||
            this.filters.storeLabel.length === 0 ||
            this.filters.storeTransporters.length === 0)
      }
    },

    optionsFields() {
      return {
        typeSearchColumn: [
          {id: 'order_number', name: 'Nº do pedido'},
          {id: 'invoice_number', name: 'Nº da nota fiscal'}
        ]
      }
    },

    //Filters
    formattedDateRange: {
      get: function() {
        return [this.filters.dateRange.init, this.filters.dateRange.end]
      },
      set: function(newValue) {
        this.filters.dateRange.init = newValue[0]
        this.filters.dateRange.end = newValue[1]
      },
    },

    //Helpers
    dateRangeInit() {
      const days = 0
      const dateOffset = (24*60*60*1000) * days
      const calculatedDate = new Date()
      calculatedDate.setTime(calculatedDate.getTime() - dateOffset)
      return calculatedDate.getTime()
    },
    dateRangeEnd() {
      const calculatedDate = new Date()
      const dateNow = new Date(calculatedDate)
      return dateNow.getTime()
    },

  },
  async mounted(){
    this.clearPagination()
    this.setInitialDate()
    this.fetchTransporters()
    await this.fetchReports()
  },
  methods:{
    ...mapActions({
      //Filters
      fetchTransporters: 'transporters/fetchTransporters',
      //Table
      fetchReportsRequested: 'orders/fetchReportsRequested',
      clearPagination: 'pagination/clearPagination',
      setPagination: 'pagination/setPagination',
      //Errors
      setError: 'error/setError',
      //Helpers
      setLoading: 'loading/setLoading',
    }),

    //Filters
    setInitialDate() {
      this.$set(this.filters.dateRange, 'init', this.dateRangeInit)
      this.$set(this.filters.dateRange, 'end', this.dateRangeEnd)
    },

    //Tags
    updateTags(){
      this.$nextTick(() => {
        this.allTags = [...this.filters.storeLabel, ...this.filters.storeTransporters, ...this.filters.storeDrivers, ...this.filters.filterOrders]
      })
    },
    removeTag(name, id){
      this.allTags = this.allTags.filter((tag) => tag.id !== id)
      this.filters.storeLabel = this.filters.storeLabel.filter((tag) => tag.name !== name)
      this.filters.storeTransporters = this.filters.storeTransporters.filter((tag) => tag.name !== name)
      this.filters.storeDrivers = this.filters.storeDrivers.filter((tag) => tag.id !== id)
      this.filters.filterOrders = this.filters.filterOrders.filter((tag) => tag.name !== name)

      this.resetSearch()
    },
    clearAllTags(){
      this.allTags = []
      this.filters.storeLabel = []
      this.filters.storeTransporters = []
      this.filters.storeDrivers = []
      this.filters.filterOrders = []

      this.resetSearch()
    },

    //Export
    async exportReport(){
      this.setLoading(true)

      const obj = {
        date_start: String(this.filters.dateRange.init),
        date_end: String(this.filters.dateRange.end),
        last_status: this.filters.lastStatus,
        'type_search_value': this.filters.filterOrders.map(el => el.name),
        'store_id': this.filters.storeLabel.map(el => el.id),
        'transporter_id': this.filters.storeTransporters.map(el => el.id),
        'type_search_column': this.filters.typeSearchColumn?.id,
        'driver_id': this.filters.storeDrivers.map(el => el.id),
        class: '\\Pax\\Exports\\OrderInternalExport',
        exportService: '\\Pax\\Services\\OrderExportExcelService',
        nameReport: `Pedidos Pax - ${this.getDateString()}`,

      }

      await orders.exportReport(obj, (r) => r, (e) => e, () => {
        this.fetchReports()
      })
    },

    getDateString() {
      let nowDate = new Date()
      let dateString = nowDate.getDate() < 10 ? '0' + nowDate.getDate() : nowDate.getDate()
      dateString += nowDate.getMonth() < 10 ? '0' + nowDate.getMonth() : nowDate.getMonth()
      dateString += nowDate.getFullYear()
      dateString += nowDate.getHours() < 10 ? '0' + nowDate.getHours() : nowDate.getHours()
      dateString += nowDate.getMinutes() < 10 ? '0' + nowDate.getMinutes() : nowDate.getMinutes()
      dateString += nowDate.getSeconds() < 10 ? '0' + nowDate.getSeconds() : nowDate.getSeconds()
      return dateString
    },

    //Search
    search() {
      this.updateTags()
    },

    fetchReports(loading = true) {
      if (loading) {
        this.setLoading(true)
      }

      this.fetchReportsRequested({page: this.page, paginate: this.paginate}).then(() => {
        this.setPagination(this.reportsRequested)
        this.checkfetchReportsRequested()
      })
    },

    checkfetchReportsRequested() {
      if (
        JSON.stringify(this.reportsRequested.data.content).indexOf('Andamento') > 0 &&
        this.$route.name === 'ordersExport'
      ) {
        if (!this.updating) {
          this.updating = true
          this.time = setTimeout(() => {
            this.fetchReports(false)
            this.updating = false
          }, this.timeOutUpdate + this.time)
          this.setPagination(this.reportsRequested)
        }
      } else {
        clearTimeout(this.time)
        this.setPagination(this.reportsRequested)
      }
    },

    //Handlers
    async download(data) {
      if (data.file != null) {
        this.setLoading(true)
        await orders.downloadReport(data.id, (r) => r, (e) => e, () => this.setLoading(false))
      }
    },

    //Reset
    resetSearch() {
      this.clearPagination().then(() => this.search())
    },

    //Errors
    showDefaultErrorMessage() {
      this.setError('O tempo limite para geração de relatório foi excedido. Por favor, diminua a quantidade de filtros ou tente novamente mais tarde.')
    },

    //Helpers
    goToIncomingOrders() {
      this.$router.push('/orders/incoming')
    },

    goToChangeStatus() {
      this.$router.push('/orders/changeStatus')
    },
  }
}
</script>
<style lang="scss" scoped>
.OrdersExport {
  &__content {
    display: grid;
    grid-template-columns: 1fr minmax(min-content, 1180px) 1fr;
    grid-template-areas: ". title ."
                         ". main .";
    grid-column-gap: 1rem;
  }
  &__Title {
    grid-area: title;
    font-weight: $font-weight-bold;
    font-style: normal;
    font-size: $font-size-mini;
    color: $color-neutral-stronger;
    margin-bottom: 20px;
  }
  &__table {
    grid-area: main;
    margin-bottom: 50px;
  }
  &__disable{
    pointer-events: none;
    opacity: .3;
  }
}
</style>
<style lang="scss">
.OrdersExport {
  .Organizer__filter {
    align-items: flex-end;
  }
}
</style>